const Flickity = require('flickity');

exports.newcarousel = () => {
  let elem = document.querySelector('.carousel');
  let carouselStatus = document.querySelector('.carousel-status');

  let carousel = new Flickity( elem, {
    // options
    cellAlign: 'left',
    contain: true,
    lazyLoad: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true
  });
  function updateStatus() {
    let slideNumber = carousel.selectedIndex + 1;
    carouselStatus.textContent = slideNumber + '/' + carousel.slides.length;
  }
  updateStatus();
  carousel.on( 'select', updateStatus );

  window.addEventListener( 'load', function() {
    carousel.resize();
  });
  return carousel;
};
