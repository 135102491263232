const carousel = require('./modules/carousel');
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
const smoothscroll = require('./modules/smoothscroll');

// Sticky Header
window.addEventListener('scroll', function() {
    const navbar = document.querySelector('.header-nav');

    if (window.pageYOffset >= navbar.offsetTop) {
        document.querySelector('.static-container').classList.add('stickynav');
    } else {
        document.querySelector('.static-container').classList.remove('stickynav');
    }
});

window.addEventListener('load', function(){
  if(document.querySelector('.intro')){
    let masterTimeline = gsap.timeline({repeat:100});
    masterTimeline.add(conceptDesignCode());
    return masterTimeline;
  }
});

window.addEventListener('load', function(){
  if(document.querySelector('.webproject')){
    let masterTimeline = gsap.timeline();
    masterTimeline.add(scrollHinweis());
    return masterTimeline;
  }
});

window.addEventListener('load', function(){
  if(document.querySelector('.header-nav')){
    const navItems = document.querySelectorAll('.header-nav .nav-item');
    let toggle = navItems[navItems.length-1];
    toggle.addEventListener('click', smoothscroll.scrollToKontakt);
  }
})

if (document.querySelector('.carousel')) {
  let myNewCarousel = carousel.newcarousel();
  // previous
  let previousButton = document.querySelector('.button--previous');
  previousButton.addEventListener( 'click', function() {
    myNewCarousel.previous();
  });
  // next
  let nextButton = document.querySelector('.button--next');
  nextButton.addEventListener( 'click', function() {
    myNewCarousel.next();
  });
}

function conceptDesignCode(){
  let step = 0.6;
  let svgStartloop = document.getElementById("homeloop").contentDocument;
  let cCode = svgStartloop.getElementById("C-Code"),
    c2Code = svgStartloop.getElementById("C2-Code"),
    oCode = svgStartloop.getElementById("O-Code"),
    o2Code = svgStartloop.getElementById("O2-Code"),
    dCode = svgStartloop.getElementById("D-Code"),
    dDesign = svgStartloop.getElementById("D-Design"),
    iDesignTElement = svgStartloop.getElementById("I-Design-T-Element"),
    eConceptStrichOben = svgStartloop.getElementById("E-Concept-Strich-Oben"),
    eConceptStrichMitte = svgStartloop.getElementById("E-Concept-Strich-Mitte"),
    eConceptStrichUnten = svgStartloop.getElementById("E-Concept-Strich-Unten"),
    tConcept = svgStartloop.getElementById("T-Concept"),
    t2Concept = svgStartloop.getElementById("T2-Concept"),
    cConcept = svgStartloop.getElementById("C1-Concept"),
    oConcept = svgStartloop.getElementById("O-Concept"),
    tl = gsap.timeline();

  tl.to(cCode, {duration: step, x:-72}, "+=0.6")
    .to(eConceptStrichOben, {duration: step, y:144.42}, "e1")
    .to(eConceptStrichMitte, {duration: step, y:144.33}, "e1")
    .to(eConceptStrichUnten, {duration: step, y:-288.75}, "e1")
    .to(oCode, {duration: step, x:-72}, "e1")
    .to(dCode, {duration: step, x:-72}, "t1")
    .to(tConcept, {duration: step, y:+120}, "t1")
    .from(t2Concept, {duration: step, y:-140}, "t1")
    .to(iDesignTElement, {duration: step*2, y:+260}, "t1")
    .to(cCode, {duration: step, y:-240}, "co-=0.6")
    .to(oCode, {duration: step, y:-240}, "co-=0.6")
    .to(dCode, {duration: step, y:-120 }, "co-=0.6")
    .to(dDesign, {duration: step, y:+120 }, "co-=0.6")
    .to(cConcept, {duration: step, y:-140}, "co-=0.6")
    .to(oConcept, {duration: step, y:-140}, "co-=0.6")
    .from(c2Code, {duration: step, y:+140}, "co-=0.6")
    .from(o2Code, {duration: step, y:+140}, "co-=0.6")
    .to(dCode, {duration: step, x:0}, "e2")
    .to(eConceptStrichOben, {duration: step, y:288.75}, "e2")
    .to(eConceptStrichMitte, {duration: step, y:-144.42}, "e2")
    .to(eConceptStrichUnten, {duration: step, y:-144.33}, "e2")
  return tl;
}
function scrollHinweis(){
  let overflowiContainer = document.querySelector('.browser-content-image').clientHeight;
  let currentPosition = document.querySelector('.browser-content-image').scrollTop;

  function requestUpdate() {
    let currentPosition = document.querySelector('.browser-content-image').scrollTop;
    if(currentPosition>overflowiContainer){
      scrollTimeline.kill();
    }
    requestAnimationFrame(requestUpdate);
  }
  requestAnimationFrame(requestUpdate);

  let scrollTimeline = gsap.timeline();

  scrollTimeline.to(".browser-content-image", {duration: 1,ease:"power3.inOut",scrollTo: overflowiContainer/2}, "+=3.5" )
  .to(".scroll-batch", {duration: 0.4, opacity: 1})
  .from("#Triangle-Runter", {duration:0.4, ease:"power1.inOut", y: -30, opacity: 0}, "-=0.2")
  .from("#Rect-Runter-2", {duration:0.4, ease:"power1.inOut", y: -20, opacity: 0}, "-=0.2")
  .from("#Rect-Runter-1", {duration:0.4, ease:"power1.inOut", y: -10, opacity: 0}, "-=0.2")
  .to(".scroll-batch", {duration: 0.8, opacity: 0}, ">1.5")
  .to(".browser-content-image", {duration: 0.8,ease:"power4.inOut",scrollTo: 0},"-=0.6");
  return scrollTimeline;
}
