const SmoothScroll = require('smooth-scroll');

const scrollToKontakt = () => {
  let scroll = new SmoothScroll();
  let options = {speed: 1000, easing: 'easeInOutQuad'}
  let anchor = document.querySelector( '#kontakt' );
  const navItems = document.querySelectorAll('.nav-item');
  let toggle = navItems[navItems.length-1];
  scroll.animateScroll( anchor, toggle, options );
};
exports.scrollToKontakt = scrollToKontakt;
